import {FC, memo} from "react";
import './styles.scss'

interface IProps {
  value: string;
  isChecked: boolean;
  onClick: () => void;
}

export const AppRadioButton: FC<IProps> = memo(({ value, isChecked, onClick }) => {
  return (
    <label className="app-radio">
      <input checked={isChecked} type="radio" onChange={onClick} />
      <span>{value}</span>
    </label>
  )
})
