export interface IMainState {
  fname: string,
  lname: string,
  email: string,
  phone: string;
  organization: string,
  position: string,
  currentSlide: number,
}

export type IMainActions =
  { type: 'setFname', value: string } |
  { type: 'setLname', value: string } |
  { type: 'setEmail', value: string } |
  { type: 'setPhone', value: string } |
  { type: 'setOrganization', value: string } |
  { type: 'setPosition', value: string } |
  { type: 'setCurrentSlide', value: number } |
  { type: 'nextSlide' } |
  { type: 'prevSlide' }

export const INITIAL_STATE: IMainState = {
  fname: '',
  lname: '',
  email: '',
  phone: '',
  organization: '',
  position: '',
  currentSlide: 0,
}

export const mainReducer = (state: IMainState, action: IMainActions): IMainState => {
  switch (action.type) {
    case "setFname":
      return {
        ...state,
        fname: action.value
      }
    case "setLname":
      return {
        ...state,
        lname: action.value
      }
    case "setEmail":
      return {
        ...state,
        email: action.value
      }
    case "setPhone":
      return {
        ...state,
        phone: action.value
      }
    case "setPosition":
      return {
        ...state,
        position: action.value
      }
    case "setOrganization":
      return {
        ...state,
        organization: action.value
      }
    case "nextSlide":
      return {
        ...state,
        currentSlide: state.currentSlide + 1
      }
    case "prevSlide":
      return {
        ...state,
        currentSlide: state.currentSlide - 1
      }
    case "setCurrentSlide":
      return  {
        ...state,
        currentSlide: action.value
      }
    default:
      return state
  }
}
