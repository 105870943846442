import { FC, memo} from "react";
import { ISlideContent } from "../types/slide-content";
import {PresentationContent, FormContent, QuestionsContent, UserPresentationContent, DownloadContent} from "./content";

export const ContentSwitch: FC<ISlideContent> = memo((props) => {
  switch (props.type) {
    case "PRESENTATION":
      return <PresentationContent {...props} />
    case "USER_PRESENTATION":
      return <UserPresentationContent {...props} />
    case "FORM":
      return <FormContent {...props} />
    case "QUESTIONS":
      return <QuestionsContent {...props} />
    case "DOWNLOAD":
      return <DownloadContent {...props} />
  }
})
