import { ContentWrapper } from "../../../../components/content-wrapper/ContentWrapper";
import { COLORS } from "../../../../constants/colors";
import { ScreenHeader } from "../../../../components/screen-header/ScreenHeader";
import { FC, useCallback, useContext, useState} from "react";
import { ISlideAnyContent } from "../../../types/slide-content";
import { AppInput } from "../../../../components/app-input/AppInput";
import { MainContext } from "../../state/context";
import { AppButton } from "../../../../components/app-button/AppButton";
import { ApiManager } from "../../../../services/ApiManager";
import { useNavigate } from "react-router";
import { useLocalStorageData } from "../../../../hooks/store-data";
import { IMainState } from "../../state/state";
import "./styles.scss";

export const FormContent: FC<ISlideAnyContent> = ({ content }) => {
  const [emailError, setEmailError] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const { state, dispatch } = useContext(MainContext);
  const { handleSetUserId, handleSetAnsweredQuestion } = useLocalStorageData();

  const navigate = useNavigate();

  const onChangeCompany = useCallback((value: string) => {
    dispatch({ type: "setOrganization", value });
  }, [dispatch]);

  const onChangePosition = useCallback((value: string) => {
    dispatch({ type: "setPosition", value });
  }, [dispatch]);

  const onChangeEmail = useCallback((value: string) => {
    setEmailError('')
    dispatch({ type: "setEmail", value });
  }, [dispatch]);

  const onChangeFname = useCallback((value: string) => {
    dispatch({ type: 'setFname', value })
  }, [dispatch])

  const onChangeLname = useCallback((value: string) => {
    dispatch({ type: 'setLname', value })
  }, [dispatch])

  const onChangePhone = useCallback((value: string) => {
    dispatch({ type: 'setPhone', value })
  }, [dispatch])

  const submit = useCallback(async () => {
     try {
       const copy = {...state}
       delete (copy as Partial<IMainState>).currentSlide;
       const user = await ApiManager.createUser(copy)
       handleSetUserId(user.id)

       if (user.answers_size) {
         handleSetAnsweredQuestion()
         dispatch({ type: "setCurrentSlide", value: 3 });
       } else {
         dispatch({ type: 'nextSlide' })
       }
     } catch (e) {
        alert("An error occurred, please try again")
     }
  }, [state, navigate, dispatch])


  const validate = () => {
    let isValid = true;
    const emailRegexp = /([^@\s]+)@((?:[-a-z0-9]+\.)+[a-z]{2,})/;
    if (!emailRegexp.test(state.email)) {
      setEmailError('Incorrect email address')
      isValid = false;
    }

    const phoneRegexp = /\+1 \([0-9]{3}\) [0-9]{3}-[0-9]{4}/;
    if (!phoneRegexp.test(state.phone)) {
      setPhoneError('Incorrect phone')
      isValid = false;
    }

    return isValid && submit()
  }

  const isDisabled = !state.email.trim().length || !state.position.trim().length || !state.organization.trim().length || !state.fname.trim().length || !state.lname.trim().length

  return (
    <section className="organization">
      <ContentWrapper backgroundColor={COLORS.MERCURY}>
        <div className="organization__container">
          <ScreenHeader {...content} />
          <div className="organization__action">
            <div className="organization__names">
              <AppInput
                value={state.fname}
                label="First name"
                onChangeText={onChangeFname}
              />
              <AppInput
                value={state.lname}
                label="Last name"
                onChangeText={onChangeLname}
              />
            </div>
            <AppInput
              value={state.organization}
              label="Company"
              onChangeText={onChangeCompany}
            />
            <AppInput
              value={state.position}
              label="Position"
              onChangeText={onChangePosition}
            />
            <AppInput
              value={state.email}
              inputType="email"
              error={emailError}
              label="Email"
              onChangeText={onChangeEmail}
            />
            <AppInput
                value={state.phone}
                inputType="tel"
                error={phoneError}
                label="Phone"
                onChangeText={onChangePhone}
            />
            <AppButton text="Submit" {...{ isDisabled }}  marginTop={16} onClick={validate} />
            <span className="privacy">
              Your information will be processed according <br /> to our{" "}
              <a className="privacy__url" target="_blank" href="https://www.lanes.ai/privacy-policy">
                privacy policy
              </a>
              . By clicking this button, you authorize Lanes to contact you
            </span>
          </div>
        </div>
      </ContentWrapper>
    </section>
  );
};
