import { Route, Routes } from "react-router";
import { PageNotFound } from "./pages/page-not-found/PageNotFound";
import { MainPage } from "./pages/main/MainPage";
import "./main.scss";
import "./styles/normalize.css";
import "./styles/reset.css";

const appHeight = () => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

window.addEventListener("resize", appHeight);

appHeight();

function App() {
  return (
    <div className="app">
      <Routes>
        <Route index element={<MainPage />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
