import { FC, useCallback, useContext, useEffect, useState } from "react";
import { COLORS } from "../../../../constants/colors";
import { ContentWrapper } from "../../../../components/content-wrapper/ContentWrapper";
import { ScreenHeader } from "../../../../components/screen-header/ScreenHeader";
import { ISlideAnyContent } from "../../../types/slide-content";
import { AppButton } from "../../../../components/app-button/AppButton";
import { MainContext } from "../../state/context";
import { ApiManager } from "../../../../services/ApiManager";
import { AnswerDto, EQuestionType, QuestionEntity } from "../../../../services/interfaces";
import { Question } from "./Question";
import { AppSlider } from "../../../../components/app-slider/AppSlider";
import { AppInput } from "../../../../components/app-input/AppInput";
import { useLocalStorageData } from "../../../../hooks/store-data";
import { AppRadioButton } from "../../../../components/app-radio-button/AppRadioButton";
import './styles.scss';

export const QuestionsContent: FC<ISlideAnyContent> = ({ content }) => {
  const [questions, setQuestions] = useState<QuestionEntity[]>([]);
  const [answers, setAnswers] = useState<Record<string, AnswerDto>>({});
  const [errors, setErrors] = useState<Record<string, string>>({});
  const { userId, handleSetAnsweredQuestion } = useLocalStorageData();
  const { dispatch } = useContext(MainContext);

  useEffect(() => {
    ApiManager.getQuestions().then((data) => {
      const defaultAnswers = data.reduce<Record<string, AnswerDto>>((acc, item) => {
        if (item.type === EQuestionType.TEXT) {
          acc[item.id] = { answer: '', question_id: item.id }
        } else if (item.type === EQuestionType.SCALE) {
          acc[item.id] = { answer: '1', question_id: item.id }
        } else {
          acc[item.id] = { answer: 'Yes', question_id: item.id }
        }

        return acc;
      }, {})

      setQuestions(data)
      setAnswers(defaultAnswers)
    })
  }, []);

  const answerQuestion = useCallback((value: string | number, questionId: string) => {
    setAnswers(prev => ({
      ...prev,
      [questionId]: { answer: value.toString(), question_id: questionId }
    }))
  }, [])

  const sendAnswers = useCallback(async () => {
    if (!userId) return

    try {
      await ApiManager.answerQuestions({user_id: userId ?? '', answers: Object.values(answers)})
      handleSetAnsweredQuestion()
      dispatch({ type: 'nextSlide' })
    } catch (e) {
      alert("An error occurred, please try again")
    }
  }, [answers, userId]);

  const validate = () => {
    let isValid = true;
    questions.forEach((question) => {
      if (question.type === EQuestionType.TEXT && !answers[question.id]?.answer?.length) {
        setErrors((prev) => ({
          ...prev,
          [question.id]: 'An empty answer'
        }))
        isValid = false;
      }
    })

    return isValid && sendAnswers()
  }

  return (
    <section className="questions">
      <ContentWrapper styles={{ overflow: 'auto', display: 'block', padding: '0 16px 16px 16px' }} backgroundColor={COLORS.MERCURY}>
        <div className="questions__container">
          <ScreenHeader {...content} />
          <div className="questions__list">
            {questions.map(({ id, text, type }, idx) => {
              const answer = answers[id]?.answer;

              return (
                <Question key={id} title={`${idx + 1}. ${text}`}>
                  {{
                    [EQuestionType.SCALE]: (
                      <AppSlider
                        value={+(answer ?? 0)}
                        min={1}
                        max={10}
                        onAfterChange={(value) => answerQuestion(value, id)}/>
                    ),
                    [EQuestionType.TEXT]: (
                      <AppInput
                        value={answer ?? ''}
                        label="Your answer"
                        error={errors[id]}
                        onChangeText={(value) => answerQuestion(value, id)}/>
                    ),
                    [EQuestionType.BOOLEAN]: (
                      <div style={{ marginTop: 8 }}>
                        <AppRadioButton
                          value="Yes"
                          isChecked={(answer || "Yes") === "Yes"}
                          onClick={() => answerQuestion("Yes", id)}
                        />
                        <AppRadioButton
                            value="No"
                            isChecked={(answer || "No") === "No"}
                            onClick={() => answerQuestion("No", id)}
                        />
                      </div>
                    ),
                  }[type]
                  }
                </Question>
              )
            })}
          </div>
          <div className="questions__next">
            <AppButton
              text='Next'
              icon='arrowRight'
              iconReverse
              width={90}
              onClick={validate}
            />
          </div>
        </div>
      </ContentWrapper>
    </section>
  )
}
