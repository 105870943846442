import { ContentWrapper } from "../../../../components/content-wrapper/ContentWrapper";
import { COLORS } from "../../../../constants/colors";
import { FC, useContext } from "react";
import { ISlidePresentationContent } from "../../../types/slide-content";
import { MainContext } from "../../state/context";
import { AppButton } from "../../../../components/app-button/AppButton";
import "./styles.scss";

export const PresentationContent: FC<ISlidePresentationContent> = ({
  content,
}) => {
  const { dispatch } = useContext(MainContext);

  return (
    <section className="presentation">
      <ContentWrapper backgroundColor={COLORS.MERCURY}>
        <img className="presentation__image" alt={""} src={content.image} />
        <div>
          <h1 className="presentation__title">
            {content.title}
          </h1>
          {!!content.description && (
            <h2
              className='presentation__description'
            >
              {content.description}
            </h2>
          )}
        </div>
        <div className="presentation__next">
          <AppButton
            text='Next'
            icon='arrowRight'
            iconReverse
            width={90}
            onClick={() => dispatch({ type: "nextSlide" })}
          />
        </div>
      </ContentWrapper>
    </section>
  );
};
