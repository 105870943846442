import { FC } from "react";
import { COLORS } from "../../constants/colors";
import { CloseIconSVG } from "../../assets/svg/CloseIconSVG";
import { CheckIconSVG } from "../../assets/svg/CheckIconSVG";
import { ArrowRightSVG } from "../../assets/svg/ArrowRightSVG";
import "./styles.scss";
import {DownloadSVG} from "../../assets/svg/DownloadSVG";
import {ExternalLinkSVG} from "../../assets/svg/ExternalLinkSVG";

interface IProps {
  text: string;
  width?: number;
  reverse?: boolean;
  marginTop?: number;
  isBordered?: boolean;
  isDisabled?: boolean;
  icon?: keyof typeof Icons;
  iconReverse?: boolean;
  onClick: () => void;
}

const Icons = {
  check: CheckIconSVG,
  close: CloseIconSVG,
  arrowRight: ArrowRightSVG,
  download: DownloadSVG,
  externalLink: ExternalLinkSVG,
};

export const AppButton: FC<IProps> = ({
  text,
  width,
  reverse,
  marginTop,
  isBordered,
  isDisabled,
  icon,
  iconReverse,
  onClick,
}) => {
  const IconComponent = Icons[icon ?? "check"];
  const color = reverse
    ? isDisabled
      ? COLORS.DARK
      : COLORS.NEPTUNE
    : COLORS.WHITE;

  return (
    <button
      disabled={isDisabled}
      className={`button${isBordered ? " button--bordered" : ""}${
        isDisabled ? " button--disabled" : ""
      }`}
      style={{
        width,
        marginTop,
        backgroundColor: reverse
          ? COLORS.WHITE
          : isDisabled
          ? COLORS.DARK
          : COLORS.NEPTUNE,
        color,
      }}
      {...{ onClick }}
    >
      {icon ? (
        <div className={`button__content${iconReverse ? " button__content--reverse" : ""}`}>
          <div className={`${iconReverse ? "" : "button__image"}`}>
            <IconComponent {...{ color }} />
          </div>
          {text}
        </div>
      ) : (
        text
      )}
    </button>
  );
};
