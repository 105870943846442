import { FC } from "react";

interface IProps {
  color?: string;
}

export const DownloadSVG: FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M7.125 3.75a4.125 4.125 0 0 0-3.477 6.346.75.75 0 1 1-1.263.808A5.625 5.625 0 1 1 12.43 6a4.125 4.125 0 0 1 3.835 5.5.75.75 0 1 1-1.414-.5 2.625 2.625 0 0 0-2.476-3.5l-.133-.002h-.03l-.103-.003h-.155a.75.75 0 0 1-.815-.573A4.127 4.127 0 0 0 7.126 3.75Zm1.882 3.758a.75.75 0 0 1 .75.75v4.94l1.72-1.72a.75.75 0 0 1 1.06 1.061l-3 3a.75.75 0 0 1-1.06 0l-3-3a.75.75 0 0 1 1.06-1.06l1.72 1.72V8.257a.75.75 0 0 1 .75-.75Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
