import { FC, memo, PropsWithChildren } from "react";

interface IProps extends PropsWithChildren {
  title: string;
}

export const Question: FC<IProps> = memo(({ children, title }) => {
  return (
    <div className='question'>
      <span className="question__text">{title}</span>
      {children}
    </div>
  )
})
