import {FC, PropsWithChildren, memo, CSSProperties} from "react";
import "./styles.scss";

interface IProps {
  paddingTop?: number;
  backgroundColor?: string;
  styles?: CSSProperties;
}

export const ContentWrapper: FC<PropsWithChildren<IProps>> = memo(
  ({ backgroundColor, styles, paddingTop, children }) => {
    return (
      <div className="content" style={{ backgroundColor, paddingTop }}>
        <div style={styles} className="content__wraper">{children}</div>
      </div>
    );
  }
);
