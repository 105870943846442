import { COLORS } from "../../../../constants/colors";
import { ContentWrapper } from "../../../../components/content-wrapper/ContentWrapper";
import { FC, useCallback } from "react";
import { ISlideDownloadContent } from "../../../types/slide-content";
import { AppButton } from "../../../../components/app-button/AppButton";
import './styles.scss';

export const DownloadContent: FC<ISlideDownloadContent> = ({ content}) => {
  const downloadFile = useCallback(() => {
    window.open(content.url, "_blank", "noreferrer");
  }, [content.url])

  return (
    <section className="download">
      <ContentWrapper backgroundColor={COLORS.MERCURY}>
        <img className="download__image" alt={""} src={content.image} />
        <div style={{ width: '100%' }}>
          <h1 className="download__title">
            {content.title}
          </h1>
          {!!content.description && (
            <h2
              className='download__description'
            >
              {content.description}
            </h2>
          )}
        </div>
        <div className="presentation__next">
          <AppButton
            text={content.button_text || 'Download'}
            icon="download"
            width={130}
            onClick={downloadFile}
          />
        </div>
      </ContentWrapper>
    </section>
  )
}
