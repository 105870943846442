import { FC } from "react";
import "./styles.scss";

interface IProps {
  pagesCount: number;
  currentPage: number;
}

export const ProgressBar: FC<IProps> = ({ pagesCount, currentPage }) => {
  if (!pagesCount) {
    return null;
  }

  return (
    <div className="progress">
      {Array(pagesCount)
        .fill("")
        .map((_, idx) => (
          <div
            key={idx.toString()}
            className={`progress__item ${idx == currentPage ? "progress__item--active" : ""}`}
          />
        ))}
    </div>
  );
};
