import axios, { AxiosInstance } from "axios";
import { CreateAnswersDto, CreateUserDto, QuestionEntity, UserEntity } from "./interfaces";

class ApiManagerClass {
  private instance: AxiosInstance;

  constructor() {
    this.instance = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: {
        common: {
          Accept: "application/json",
        },
      },
    });
  }

  public getUser = async (userId: string) => {
    try {
      return (await this.instance.get<UserEntity>(`/users/${userId}`)).data
    } catch (e) {
      console.error('createUser error: ', e);
      throw e;
    }
  }

  public async createUser(data: CreateUserDto) {
    try {
      return (
        await this.instance.post<UserEntity>(`/users`, { ...data })
      ).data;
    } catch (e) {
      console.error('createUser error: ', e);
      throw e;
    }
  }

  public async getQuestions() {
    try {
      return (await this.instance.get<QuestionEntity[]>('/questions')).data
    } catch (e) {
      console.error('getQuestions error: ', e);
      throw e;
    }
  }

  public async answerQuestions(data: CreateAnswersDto) {
    try {
      return (await this.instance.post<{ message: 'ok'}>('/answers', { ...data })).data
    } catch (e) {
      console.error('answerQuestions error: ', e);
      throw e;
    }
  }
}

export const ApiManager = new ApiManagerClass();
