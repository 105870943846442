import {useCallback, useState} from "react";

export const useLocalStorageData = () => {
  const [userId, setUserId] = useState(localStorage.getItem("@user_id"));
  const [answeredQuestions, setAnsweredQuestions] = useState(!!localStorage.getItem("@answered_questions"));

  const handleSetUserId = useCallback((id: string) => {
    localStorage.setItem('@user_id', id)
    setUserId(id)
  }, [])

  const handleSetAnsweredQuestion = useCallback(() => {
    localStorage.setItem('@answered_questions', "true")
    setAnsweredQuestions(true)
  }, [])

  return {
    userId,
    answeredQuestions,
    handleSetUserId,
    handleSetAnsweredQuestion,
  };
};
