import {FC} from "react";

interface IProps {
  color?: string;
}

export const ArrowRightSVG: FC<IProps> = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={19} height={14} fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M8.97 3.22a.75.75 0 0 1 1.06 0l5.25 5.25a.75.75 0 0 1 0 1.06l-5.25 5.25a.75.75 0 1 1-1.06-1.06l3.97-3.97H4.25a.75.75 0 0 1 0-1.5h8.69L8.97 4.28a.75.75 0 0 1 0-1.06Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
