import { FC } from "react";
import { COLORS } from "../../constants/colors";

interface IProps {
  color?: string;
}

export const CloseIconSVG: FC<IProps> = ({ color = COLORS.NEPTUNE }) => {
  return (
    <svg
      width="10"
      height="10"
      viewBox="0 0 10 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.528758 0.528575C0.789108 0.268226 1.21122 0.268226 1.47157 0.528575L5.00016 4.05717L8.52876 0.528575C8.78911 0.268226 9.21122 0.268226 9.47157 0.528575C9.73192 0.788925 9.73192 1.21103 9.47157 1.47138L5.94297 4.99998L9.47157 8.52858C9.73192 8.78893 9.73192 9.21103 9.47157 9.47138C9.21122 9.73173 8.78911 9.73173 8.52876 9.47138L5.00016 5.94279L1.47157 9.47138C1.21122 9.73173 0.789108 9.73173 0.528758 9.47138C0.268409 9.21103 0.268409 8.78893 0.528758 8.52858L4.05735 4.99998L0.528758 1.47138C0.268409 1.21103 0.268409 0.788925 0.528758 0.528575Z"
        fill={color}
      />
    </svg>
  );
};
