import { ChangeEvent, FC, HTMLInputTypeAttribute, memo, useCallback, useState } from "react";
import InputMask from 'react-input-mask';
import './styles.scss';

interface IProps {
  value: string;
  label: string;
  error?: string;
  placeholder?: string;
  inputType?: HTMLInputTypeAttribute;
  onChangeText?: (text: string) => void;
}

export const AppInput: FC<IProps> = memo(({  value, label, error, inputType = 'text', placeholder, onChangeText }) => {
  const [isFocused, setIsFocused] = useState(!!value);
  const isError = !!error;

  const onChange = useCallback(({ target }: ChangeEvent<HTMLInputElement>) => {
    onChangeText?.(target.value)
  }, [onChangeText])

  const onFocus = useCallback(() => {
    setIsFocused(true)
  }, [])

  const onBlur = useCallback(() => {
    if (!value || value === '+1 (') {
      setIsFocused(false)
    }
  }, [value, onChangeText])

  return (
    <label className="app-input">
      { inputType === 'tel' ?
          <InputMask className={ `app-input__field ${ isError ? ' app-input__field--error' : '' }` }
                     mask="+1 (999) 999-9999"
                     maskChar=''
                     type='tel'
                     { ...{value, placeholder, onChange, onFocus, onBlur} }>
          </InputMask> :
          <input className={ `app-input__field ${ isError ? ' app-input__field--error' : '' }` }
                 type={ inputType }
                 { ...{value, placeholder, onChange, onFocus, onBlur} }
          />
      }
      <span
          className={ `app-input__placeholder${ isFocused ? ' app-input__placeholder--active' : '' }${ isError ? ' app-input__placeholder--error' : '' }` }>{ label }</span>
      { isError && (
          <span className="app-input__error">{error}</span>
      )}
    </label>
  )
})
