import { FC, memo } from "react";
import ReactSlider from "react-slider";
import './styles.scss';

interface IProps {
  value: number;
  min: number;
  max: number;
  marks?: [number, number];
  onAfterChange: (value: number) => void;
}

export const AppSlider: FC<IProps> = memo(({ min, max, marks, value, onAfterChange }) => {
  return (
    <div className='slider'>
      <ReactSlider
        className="slider__container"
        trackClassName="slider__track"
        thumbClassName="slider__thumb"
        {...{ value, onAfterChange, max, min }}
        renderThumb={(props, state) => (
          <div {...props}>
            {state.valueNow !== min && state.valueNow !== max && (
              <span style={{ position: 'absolute', width: '100%', top: -20, display: 'flex', justifyContent: 'center' }}>{state.valueNow}</span>
            )}
          </div>
        )}
        renderMark={(props) => {
          return (
            <span {...props}>{props.key}</span>
          )
        }}
        markClassName="slider__mark"
        marks={[1, 10]}
      />
    </div>
  )
})
