import { FC, useContext } from "react";
import { ISlideUserPresentationContent } from "../../../types/slide-content";
import { COLORS } from "../../../../constants/colors";
import { ContentWrapper } from "../../../../components/content-wrapper/ContentWrapper";
import { ScreenHeader } from "../../../../components/screen-header/ScreenHeader";
import { MainContext } from "../../state/context";
import "./styles.scss";
import { AppButton } from "../../../../components/app-button/AppButton";

export const UserPresentationContent: FC<ISlideUserPresentationContent> = ({
  content,
}) => {
  const { dispatch } = useContext(MainContext);

  return (
    <section className="user-presentation">
      <ContentWrapper backgroundColor={COLORS.MERCURY}>
        <ScreenHeader {...content} />
        <div className="user-presentation__images">
          {content.users.map((el, idx) => (
            <div key={el.name + idx} className="user-presentation__user">
              <img
                className="user-presentation__image"
                alt=""
                src={el.image}
              />
              <span className="user-presentation__user-name">{el.name}</span>
            </div>
          ))}
        </div>
        <div className="user-presentation__footer">
          <img
            alt=""
            src={content.footer.image}
            className="user-presentation__footer-image"
          />
          <span className="user-presentation__footer-description">
            {content.footer.description}
          </span>
        </div>
        <AppButton
          text='Next'
          icon='arrowRight'
          iconReverse
          width={90}
          onClick={() => dispatch({ type: "nextSlide" })}
        />
      </ContentWrapper>
    </section>
  );
};
