import { FC } from "react";
import { ContentWrapper } from "../../components/content-wrapper/ContentWrapper";
import "./styles.scss";

export const PageNotFound: FC = () => {
  return (
    <ContentWrapper>
      <div className="no-access--container">
        <span className="no-access--title">404</span>
        <span className="no-access--description">Page not found</span>
      </div>
    </ContentWrapper>
  );
};
