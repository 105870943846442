export interface UserEntity {
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  fname: string;
  lname: string;
  email: string;
  position: string;
  organization: string;
  answers_size: number;
}

export interface CreateUserDto {
  fname: string;
  lname: string;
  email: string;
  position: string;
  organization: string;
}

export interface AnswerDto {
  question_id: string;
  answer: string;
}

export interface CreateAnswersDto {
  answers: AnswerDto[];
  user_id: string;
}

export interface QuestionEntity {
  id: string;
  /** @format date-time */
  created_at: string;
  /** @format date-time */
  updated_at: string;
  text: string;
  type: EQuestionType;
}

export enum EQuestionType {
  TEXT = 'TEXT',
  BOOLEAN = 'BOOLEAN',
  SCALE = 'SCALE',
}
