import { useEffect, useReducer } from "react";
import { ContentSwitch } from "./ContentSwitch";
import { ISlideContent } from "../types/slide-content";
import { INITIAL_STATE, mainReducer } from "./state/state";
import { MainContext } from "./state/context";
import { ProgressBar } from "../../components/progress-bar/ProgressBar";
import { useLocalStorageData } from "../../hooks/store-data";
import slides from "../../constants/slides.json";
import "./styles.scss";

export const MainPage = () => {
  const [state, dispatch] = useReducer(mainReducer, INITIAL_STATE);
  const slide = slides[state.currentSlide] as ISlideContent;
  const { userId, answeredQuestions } = useLocalStorageData();

  useEffect(() => {
    if (userId) {
      let redirectIndex: number;

      if (answeredQuestions) {
        redirectIndex = slides.findIndex(el => el.type === 'DOWNLOAD')
      } else {
        redirectIndex = slides.findIndex(el => el.type === 'QUESTIONS')
      }

      dispatch({ type: 'setCurrentSlide', value: redirectIndex })
    }
  }, [userId]);

  return (
    <main className="main">
      <MainContext.Provider value={{ state, dispatch }}>
        <ProgressBar
          pagesCount={slides.length}
          currentPage={state.currentSlide}
        />
        <ContentSwitch key={slide.id} {...slide} />
      </MainContext.Provider>
    </main>
  );
};
