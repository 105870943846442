import { FC } from "react";
import "./styles.scss";

interface IProps {
  title: string;
  description?: string;
  useLightAppearance?: boolean;
}

export const ScreenHeader: FC<IProps> = ({
  title,
  description,
}) => {
  return (
    <header>
      <h1 className="header">
        {title}
      </h1>
      {!!description && (
        <p className="description">
          {description}
        </p>
      )}
    </header>
  );
};
