export const ExternalLinkSVG = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={16} fill="none">
      <path
        fill="#fff"
        fillRule="evenodd"
        d="M11.25 3a.75.75 0 0 1 0-1.5h4.5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0V4.06l-6.97 6.97a.75.75 0 1 1-1.06-1.06L13.94 3h-2.69Zm-7.5 2.25A.75.75 0 0 0 3 6v8.25a.75.75 0 0 0 .75.75H12a.75.75 0 0 0 .75-.75v-4.5a.75.75 0 0 1 1.5 0v4.5A2.25 2.25 0 0 1 12 16.5H3.75a2.25 2.25 0 0 1-2.25-2.25V6a2.25 2.25 0 0 1 2.25-2.25h4.5a.75.75 0 0 1 0 1.5h-4.5Z"
        clipRule="evenodd"
      />
    </svg>
  )
}
